import React from 'react'

import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Footer(): JSX.Element {
    return (
        <div className="Footer container">
            <p> Trading Tools Made with <span className="heart">❤️</span> by by the Compendium Team</p>
        </div>
    )
}

export default Footer
