import React from "react";
import Header from "./components/Header";
import Card from "./components/Card";
import Footer from "./components/Footer";
import items from "./assets/items";
import dexBanner from "./assets/banners/dex_banner.png";
import compendiumApplication from "./assets/banners/compendium_application.png";
import dexApp from "./assets/banners/dex_application.png";
import pendax from "./assets/banners/pendax.png";
import docs from "./assets/banners/docs.png";
import discord from "./assets/banners/discord.png";
import telegram from "./assets/banners/telegram.png";
import token from "./assets/banners/token.png";
import coinmarketCap from "./assets/banners/coinmarket.png";
import coingecko from "./assets/banners/coingecko.png";
import collection from "./assets/banners/collection.png";
import magicEden from "./assets/banners/magic_eden.png";
import twitter from "./assets/banners/twitter.png";
import github from "./assets/banners/github.png";
import youtube from "./assets/banners/youtube.png";
import facebook from "./assets/banners/facebook.png";
import reddit from "./assets/banners/reddit.png";
import medium from "./assets/banners/medium.png";
import odysse from "./assets/banners/odysse.png";
import "./css/skeleton.css";
import "./css/normalize.css";

function App(): JSX.Element {
  return (
    <div className="App">
      <Header></Header>
      <div className="container ">
        <h1 className="title"> Core Ecosystem Products</h1>
        <div className="row">
          <Card
            i={0}
            title={"Compendium Application"}
            subtitle={
              "Trading bots, cross-exchange copy trading, signal provider groups, and more for your favorite crypto exchanges. "
            }
            link={"https://compendium.finance/"}
            cover={compendiumApplication}
            children={[]}
          />
          <Card
            i={0}
            title={"Build With PENDAX "}
            subtitle={
              "Connecting the world's leading exchanges with an easy to use JavaScript SDK and example libraries."
            }
            link={"https://pendax.pro/"}
            cover={pendax}
            children={[]}
          />
          <Card
            i={0}
            title={"The Compendex Suite"}
            subtitle={
              "Access trading tools and analytics for multiple supported blockchain networks. Currently available for Solana. "
            }
            link={"https://compendex.xyz/"}
            cover={dexApp}
            children={[]}
          />
        </div>
        <h1 className="title">Learn And Communicate</h1>
        <div className="row">
          <Card
            i={0}
            title={"Full Documentation"}
            subtitle={""}
            link={"https://docs.compendium.finance/"}
            cover={docs}
            children={[]}
          />
          <Card
            i={0}
            title={"Join Official Discord"}
            subtitle={""}
            link={"https://discord.gg/64r2xtqczs"}
            cover={discord}
            children={[]}
          />
          <Card
            i={0}
            title={"Join Official Telegram"}
            subtitle={""}
            link={"https://t.me/CompendiumFinanceOfficial"}
            cover={telegram}
            children={[]}
          />
        </div>
        <h1 className="title"> The CMFI Token</h1>

        <div className="row">
          <Card
            i={0}
            title={"Token Information"}
            subtitle={""}
            link={"https://compendium.finance/token"}
            cover={token}
            children={[]}
          />
          <Card
            i={0}
            title={"CoinMarketCap"}
            subtitle={""}
            link={"https://coinmarketcap.com/currencies/compendium-finance/"}
            cover={coinmarketCap}
            children={[]}
          />
          <Card
            i={0}
            title={"CoinGecko"}
            subtitle={""}
            link={"https://www.coingecko.com/en/coins/compendium-fi"}
            cover={coingecko}
            children={[]}
          />
        </div>
        <h1 className="title">Compendi-Pigs NFT Collection</h1>

        <div className="row">
          <Card
            i={0}
            title={"Explore The Collection"}
            subtitle={""}
            link={"https://compendium.finance/piggies"}
            cover={collection}
            children={[]}
          />
          <Card
            i={0}
            title={"Trade on MagicEden"}
            subtitle={""}
            link={"https://magiceden.io/marketplace/compendipigs"}
            cover={magicEden}
            children={[]}
          />
          <Card
            i={0}
            title={"View on Compendex"}
            subtitle={""}
            link={"https://dex.compendium.fi/nft/collections/compendipigs"}
            cover={dexApp}
            children={[]}
          />
        </div>
        <h1 className="title">Official Ecosystem Social Links</h1>

        <div className="row social">
          <Card
            i={0}
            title={"Compendium Twitter"}
            subtitle={""}
            link={"https://twitter.com/CompendiumFi"}
            cover={twitter}
            children={[]}
          />
          <Card
            i={0}
            title={"Pendax Twitter"}
            subtitle={""}
            link={"https://twitter.com/PendaxPro"}
            cover={twitter}
            children={[]}
          />
          <Card
            i={0}
            title={"Compendex Twitter"}
            subtitle={""}
            link={"https://twitter.com/Compendexyz"}
            cover={twitter}
            children={[]}
          />
        </div>
        <div className="row social">
          <Card
            i={0}
            title={"Github"}
            subtitle={""}
            link={"https://github.com/CompendiumFi"}
            cover={github}
            children={[]}
          />
          <Card
            i={0}
            title={"Youtube"}
            subtitle={""}
            link={"https://www.youtube.com/channel/UC749uyBnzwgTegYa0LkSj5w"}
            cover={youtube}
            children={[]}
          />
          <Card
            i={0}
            title={"Facebook"}
            subtitle={""}
            link={"https://www.facebook.com/CompendiumFi"}
            cover={facebook}
            children={[]}
          />
        </div>
        <div className="row social">
          <Card
            i={0}
            title={"Medium"}
            subtitle={""}
            link={"https://compendiumfi.medium.com/"}
            cover={medium}
            children={[]}
          />
          <Card
            i={0}
            title={"Reddit"}
            subtitle={""}
            link={"https://www.reddit.com/r/CompendiumFi/"}
            cover={reddit}
            children={[]}
          />
          <Card
            i={0}
            title={"Odysse"}
            subtitle={""}
            link={"https://odysee.com/@compendiumfi:9"}
            cover={odysse}
            children={[]}
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
{
  /* {items.map((item, i: number) => {
            return (
              <Card
                i={i}
                title={item.title}
                subtitle={item.subtitle}
                link={item.link}
                cover={item.image}
                children={[]}
              />
            );
          })} */
}
